import { formatDate2, formatDateRange2, isDateBefore } from '~/services/date'
import {
  getFiscalFormat,
  getFiscalYear,
  getFiscalYearDateRange,
  getYearDateRange
} from '~/services/period'
import { parseHTMLFromString } from '~/services/html'
import {
  LEGAL_REPORTS_CONFIG_BY_TYPE,
  LEGAL_REPORT_SCOPE_MAIN_DIMENSION_TITLE
} from '~/constants/legalReports/main'
import type { Filter } from '~/types/analytics'
import {
  SectionTitleLevel,
  type LegalReportType,
  type TableOfContent,
  type LegalReport,
  type PartialLegalReport,
  type LegalReportParams,
  LegalReportTemplate
} from '~/types/legalReports/main'
import { PERIOD_ID } from '~/constants/period'
import { PeriodType } from '~/types/timeDimension'
import { useCustomizationStore } from '~/stores/customization'
import { GET_LEGAL_REPORT_TEMPLATE_OPTIONS_QUERY } from '~/gql/legalReports/getLegalReportTemplateOptionsQuery'

export const getLegalReportTypeTitle = (legalReportType: LegalReportType) => {
  const { $t } = useNuxtApp()
  return `${LEGAL_REPORTS_CONFIG_BY_TYPE[legalReportType].emoji} ${$t(
    LEGAL_REPORTS_CONFIG_BY_TYPE[legalReportType].typeTitle
  )}`
}

export const getLegalReportTitle = (legalReport: PartialLegalReport) => {
  const { $t } = useNuxtApp()
  const title = `${$t(LEGAL_REPORTS_CONFIG_BY_TYPE[legalReport.type].title)} ${
    legalReport.year
  }`

  return title
}

export const getLegalReportTemplates = async (
  legalReportType: LegalReportType
) => {
  const { $apiGqlClient } = useNuxtApp()

  return (
    await $apiGqlClient.query({
      query: GET_LEGAL_REPORT_TEMPLATE_OPTIONS_QUERY,
      variables: { legalReportType }
    })
  ).data.getLegalReportTemplateOptions as LegalReportTemplate[]
}

export const getMainLegalReportFilter = (filters: Filter[]) => {
  return filters.find(
    filter => filter.member === LEGAL_REPORT_SCOPE_MAIN_DIMENSION_TITLE
  )
}

export const formatSectionTitle = (title: string, order: number) =>
  `${order + 1}. ${title}`

export const computeTableOfContents = (
  legalReport: LegalReport
): TableOfContent => {
  return legalReport.legalReportSections.map(legalReportSection => ({
    id: legalReportSection.id,
    description: legalReportSection.description,
    titles: [
      {
        sectionId: legalReportSection.id,
        title: formatSectionTitle(
          legalReportSection.title,
          legalReportSection.order
        ),
        level: SectionTitleLevel.H1
      },
      ...(
        Array.from(
          parseHTMLFromString(legalReportSection.content).querySelectorAll(
            Object.values(SectionTitleLevel).toString()
          )
        ) as HTMLElement[]
      ).map(element => ({
        sectionId: legalReportSection.id,
        title: element.innerText,
        level: element.tagName as SectionTitleLevel
      }))
    ]
  }))
}

export const getLegalReportRealizedYears = (
  LegalReportYear: number,
  realizedYearsNumber: number
): number[] => {
  const realizedYearsIndex = Array.from(
    { length: realizedYearsNumber },
    (_, index) => -index - 1
  ).toReversed()

  return realizedYearsIndex.map(yearIndex => LegalReportYear + yearIndex)
}

export const getLegalReportTrendYears = (
  LegalReportYear: number,
  trendYearsNumber: number
): number[] => {
  const trendYearsIndex = Array.from(
    { length: trendYearsNumber },
    (_, index) => index
  )

  return trendYearsIndex.map(yearIndex => LegalReportYear + yearIndex)
}

export const getLegalReportRealizedPeriod = (
  legalReport: Pick<LegalReportParams, 'year' | 'realizedYearsNumber'>
): [string, string] => {
  let startDate: Date
  let endDate: Date

  const { isInFiscalMode } = useCustomizationStore()

  if (isInFiscalMode) {
    startDate = getFiscalYearDateRange(
      legalReport.year - legalReport.realizedYearsNumber
    )[0]
    endDate = getFiscalYearDateRange(legalReport.year - 1)[1]
  } else {
    startDate = new Date(
      legalReport.year - legalReport.realizedYearsNumber,
      0,
      1
    )
    endDate = new Date(legalReport.year - 1, 11, 31)
  }
  return [
    formatDate2(startDate),
    formatDate2(isDateBefore(new Date(), endDate) ? new Date() : endDate)
  ]
}

export const getLegalReportYearOptions = () => {
  const { isInFiscalMode } = useCustomizationStore()

  const currentYear = isInFiscalMode
    ? getFiscalYear(new Date())
    : new Date().getFullYear()

  const availableYears =
    currentYear === new Date().getFullYear()
      ? [currentYear + 1, currentYear, currentYear - 1, currentYear - 2]
      : [currentYear, currentYear - 1, currentYear - 2]

  return availableYears.map(year => ({
    label: getFiscalFormat(year.toString(), isInFiscalMode),
    value: year
  }))
}

export const getLegalReportYearPeriods = (
  year: number,
  realizedYearsNumber: number
) => {
  const { isInFiscalMode } = useCustomizationStore()

  return getLegalReportRealizedYears(year, realizedYearsNumber).map(
    (year, index) => {
      const [yearStartDate, yearEndDate] = getYearDateRange(
        year,
        isInFiscalMode
      )

      const [yearBeforeStartDate, yearBeforeEndDate] = getYearDateRange(
        year,
        isInFiscalMode
      )

      return {
        periodId:
          PERIOD_ID[`LEGAL_REPORT_YEAR_${index + 1}` as keyof typeof PERIOD_ID],
        isFiscal: isInFiscalMode,
        periodType: PeriodType.YEAR,
        dateRange: formatDateRange2([yearStartDate, yearEndDate]),
        isFuture: false,
        compareDateRange: formatDateRange2([
          yearBeforeStartDate,
          yearBeforeEndDate
        ]),
        trendDateRange: formatDateRange2([yearBeforeStartDate, yearEndDate])
      }
    }
  )
}
