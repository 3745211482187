import { gql } from 'graphql-tag'

const LEGAL_REPORT_SECTION_FIELDS = `
  id
  order
  content
  title
  description
  isPublished
`

const LEGAL_REPORT_FIELDS = `
  id
  year
  type
  createdAt
  publishedAt
  legalReportSections {
    ${LEGAL_REPORT_SECTION_FIELDS}
  }
  legalReportFilters {
    member
    operator
    values
  }
  realizedYearsNumber
  trendYearsNumber
  legalReportAttachments {
    id
    attachmentId
    filename
    uploadedAt
    size
    isPublished
  }
`

export const LEGAL_REPORT_FRAGMENT = gql`
  fragment LegalReportFields on LegalReport {
    ${LEGAL_REPORT_FIELDS}
  }
`

export const LEGAL_REPORT_WITH_ACCESS_TYPE_FRAGMENT = gql`
  fragment LegalReportWithAccessTypeFields on LegalReportWithAccessTypeDto {
    ${LEGAL_REPORT_FIELDS}
    hasEditorAccess
  }
`

export const LEGAL_REPORT_SECTION_FRAGMENT = gql`
  fragment LegalReportSectionFields on LegalReportSection {
    ${LEGAL_REPORT_SECTION_FIELDS}
  }
`
