import { FilterOperator } from '~/types/cube'
import { type Filter, type ScopeFilter } from '~/types/analytics'
import type { StandardFilters } from '~/types/filter'
import { UPDATE_USER_FILTER_TRACKING_MUTATION } from '~/gql/userTracking/updateUserFilterTrackingMutation'

export const getCustomFiltersFromStandardFilters = (
  standardFilters: StandardFilters
): Filter[] => {
  const customFilters: Filter[] = []
  Object.entries(standardFilters).forEach(([title, { values }]) => {
    if (values && values.length > 0) {
      customFilters.push({
        member: title,
        operator: FilterOperator.EQUALS,
        values: values.map(option => option.value.toString())
      })
    }
  })

  return customFilters
}

// Compare scope filters, order matters
export const areSameScopeFilters = (
  scopeFilters1: ScopeFilter[],
  scopeFilters2: ScopeFilter[]
) => {
  if (scopeFilters1.length !== scopeFilters2.length) {
    return false
  }
  return !scopeFilters1.some(
    (scopeFilter1, filterIndex) =>
      scopeFilter1.operator !== scopeFilters2[filterIndex]!.operator ||
      scopeFilter1.dataType !== scopeFilters2[filterIndex]!.dataType ||
      scopeFilter1.member !== scopeFilters2[filterIndex]!.member ||
      scopeFilter1.values.length !==
        scopeFilters2[filterIndex]!.values.length ||
      scopeFilter1.values.some(
        (scopeFilterValue1, valueIndex) =>
          scopeFilterValue1 !== scopeFilters2[filterIndex]!.values[valueIndex]
      )
  )
}

export const getFormattedFilterValues = (filter: Filter | undefined) => {
  if (filter) {
    return filter.values?.join(', ')
  } else {
    return undefined
  }
}

export const hasSameFilterValues = (
  firstValues: string[] | undefined,
  secondValues: string[] | undefined
) => {
  if (!firstValues || !secondValues) {
    return firstValues === secondValues
  }
  return (
    JSON.stringify(firstValues.sort()) === JSON.stringify(secondValues.sort())
  )
}

export const updateUserFilterTracking = async (filter: Filter) => {
  const { $apiGqlClient } = useNuxtApp()

  await $apiGqlClient.mutate({
    mutation: UPDATE_USER_FILTER_TRACKING_MUTATION,
    variables: {
      filter: {
        member: filter.member,
        operator: filter.operator,
        values: filter.values
      }
    }
  })
}
