import { gql } from 'graphql-tag'
import { LEGAL_REPORT_FRAGMENT } from './legalReportFragment'

export const CREATE_LEGAL_REPORT_SECTION_MUTATION = gql`
  ${LEGAL_REPORT_FRAGMENT}
  mutation CREATE_LEGAL_REPORT_SECTION_MUTATION(
    $id: String!
    $section: LegalReportSectionInputDto!
  ) {
    createLegalReportSection(id: $id, section: $section) {
      ...LegalReportFields
    }
  }
`
