import {
  addDays,
  addYears,
  differenceInDays,
  isSameDay,
  startOfYear,
  subYears
} from 'date-fns'
import { positiveMod } from './number'
import {
  type BasicPeriod,
  type FullPeriod,
  PeriodType,
  TimeGrain,
  TimeDimensionStoreContext,
  type Period
} from '~/types/timeDimension'
import {
  formatDate2,
  isDateValid,
  getDateMinusDays,
  getDateMinusMonth,
  formatDateRange2
} from '~/services/date'
import { getLegalReportYearPeriods } from '~/services/legalReports/main'
import { FISCAL_YEAR_START_MONTH_DEFAULT_OPTION } from '~/constants/customization'
import { useCustomizationStore } from '~/stores/customization'
import {
  ALL_TIME_PERIOD,
  DASHBOARD_PERIOD_IDS,
  PERIOD_ID
} from '~/constants/period'
import { useLegalReportsStore } from '~/stores/legalReports'

const PERIOD_OPTIONS: {
  [key in Exclude<PeriodType, PeriodType.CUSTOM>]: {
    trendSubPeriod: TimeGrain
    chartSubPeriod: TimeGrain
  }
} = {
  [PeriodType.YEAR]: {
    trendSubPeriod: TimeGrain.QUARTER,
    chartSubPeriod: TimeGrain.QUARTER
  },
  [PeriodType.QUARTER]: {
    trendSubPeriod: TimeGrain.MONTH,
    chartSubPeriod: TimeGrain.MONTH
  },
  [PeriodType.MONTH]: {
    trendSubPeriod: TimeGrain.MONTH,
    chartSubPeriod: TimeGrain.WEEK
  }
}

export const getDateQuarterNumber = (
  date: Date,
  fiscalStartMonth: number,
  isInFiscalMode: boolean
) => {
  // month of the fiscal year (0 - 11)
  const adjustedMonth = isInFiscalMode
    ? positiveMod(date.getMonth() + 1 - fiscalStartMonth, 12)
    : date.getMonth()

  return Math.floor(adjustedMonth / 3) + 1
}

export const getQuarterPeriodByYearAndQuarterNumber = (
  yearStartDate: Date,
  quarterNumber: number
): [Date, Date] => {
  const startDate = new Date(yearStartDate)

  const quarterStartDate = new Date(
    startDate.setMonth(startDate.getMonth() + (quarterNumber - 1) * 3)
  )

  const quarterEndDate = new Date(
    quarterStartDate.getFullYear(),
    quarterStartDate.getMonth() + 3,
    0
  )

  return [quarterStartDate, quarterEndDate]
}

const getMonthStartDateAndEndDate = (year: number, monthNumber: number) => {
  return {
    startDate: new Date(year, monthNumber, 1),
    endDate: new Date(year, monthNumber + 1, 0)
  }
}

export const getPeriodOptions = (
  context: TimeDimensionStoreContext
): Period[] => {
  const allPeriodChoices = getAllPeriods()

  switch (context) {
    case TimeDimensionStoreContext.DASHBOARD: {
      const currentQuarterPeriodOptions =
        getCurrentQuarterPeriodOptions(allPeriodChoices)
      return currentQuarterPeriodOptions
    }

    case TimeDimensionStoreContext.LEGAL_REPORT: {
      const { legalReport } = useLegalReportsStore()

      return [
        ...getLegalReportYearPeriods(
          legalReport!.year,
          legalReport!.realizedYearsNumber
        ),
        allPeriodChoices.YTD
      ]
    }

    case TimeDimensionStoreContext.SETTINGS: {
      const currentQuarterPeriodOptions =
        getCurrentQuarterPeriodOptions(allPeriodChoices)
      return [ALL_TIME_PERIOD, ...currentQuarterPeriodOptions]
    }
  }
}

const getCurrentQuarterPeriodOptions = (allPeriodChoices: {
  [key in (typeof DASHBOARD_PERIOD_IDS)[number]]: Period
}): Period[] => {
  const { isDashboardInFiscalMode, fiscalYearStartMonth } =
    useCustomizationStore()

  const todayDate = new Date()
  const currentQuarterNumber = getDateQuarterNumber(
    todayDate,
    fiscalYearStartMonth,
    isDashboardInFiscalMode
  )

  if (currentQuarterNumber === 1) {
    return [
      allPeriodChoices.LAST_YEAR_Q4,
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.CURRENT_YEAR
    ]
  }

  if (currentQuarterNumber === 2) {
    return [
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.YTD,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR_Q2,
      allPeriodChoices.CURRENT_YEAR
    ]
  }

  if (currentQuarterNumber === 3) {
    return [
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.CURRENT_YEAR_Q2,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.YTD,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR_Q3,
      allPeriodChoices.CURRENT_YEAR
    ]
  } else {
    return [
      allPeriodChoices.LAST_YEAR,
      allPeriodChoices.CURRENT_YEAR_Q1,
      allPeriodChoices.CURRENT_YEAR_Q2,
      allPeriodChoices.CURRENT_YEAR_Q3,
      allPeriodChoices.LAST_MONTH,
      allPeriodChoices.LAST_12_MONTHS_TO_DATE,
      allPeriodChoices.YTD,
      allPeriodChoices.QTD,
      allPeriodChoices.MTD,
      allPeriodChoices.CURRENT_MONTH,
      allPeriodChoices.CURRENT_YEAR
    ]
  }
}

const getAllPeriods = (): {
  [key in (typeof DASHBOARD_PERIOD_IDS)[number]]: Period
} => {
  const { isInFiscalMode, fiscalYearStartMonth } = useCustomizationStore()

  const todayDate = new Date()
  const currentCalendarYear = todayDate.getFullYear()

  const yearStartMonth = isInFiscalMode
    ? fiscalYearStartMonth
    : Number(FISCAL_YEAR_START_MONTH_DEFAULT_OPTION)

  const currentYear = isInFiscalMode
    ? getFiscalYear(todayDate)
    : todayDate.getFullYear()

  const [currentYearStartDate, currentYearEndDate] = getYearDateRange(
    currentYear,
    isInFiscalMode
  )

  const [lastYearStartDate, lastYearEndDate] = getYearDateRange(
    currentYear - 1,
    isInFiscalMode
  )

  const [secondLastYearStartDate, secondLastYearEndDate] = getYearDateRange(
    currentYear - 2,
    isInFiscalMode
  )

  const [nextYearStartDate, nextYearEndDate] = getYearDateRange(
    currentYear + 1,
    isInFiscalMode
  )

  const currentMonth = todayDate.getMonth()
  const currentQuarterNumber = getDateQuarterNumber(
    todayDate,
    yearStartMonth,
    isInFiscalMode
  )

  const [currentQuarterStartDate] = getQuarterPeriodByYearAndQuarterNumber(
    currentYearStartDate,
    currentQuarterNumber
  )!

  const secondLastYearQ4Period = getQuarterPeriodByYearAndQuarterNumber(
    subYears(currentYearStartDate, 2),
    4
  )!

  const lastYearQ1Period = getQuarterPeriodByYearAndQuarterNumber(
    subYears(currentYearStartDate, 1),
    1
  )!

  const lastYearQ2Period = getQuarterPeriodByYearAndQuarterNumber(
    subYears(currentYearStartDate, 1),
    2
  )!

  const lastYearQ3Period = getQuarterPeriodByYearAndQuarterNumber(
    subYears(currentYearStartDate, 1),
    3
  )!

  const lastYearQ4Period = getQuarterPeriodByYearAndQuarterNumber(
    subYears(currentYearStartDate, 1),
    4
  )!

  const currentYearQ1Period = getQuarterPeriodByYearAndQuarterNumber(
    currentYearStartDate,
    1
  )!

  const currentYearQ2Period = getQuarterPeriodByYearAndQuarterNumber(
    currentYearStartDate,
    2
  )!

  const currentYearQ3Period = getQuarterPeriodByYearAndQuarterNumber(
    currentYearStartDate,
    3
  )!

  const currentYearQ4Period = getQuarterPeriodByYearAndQuarterNumber(
    currentYearStartDate,
    4
  )!

  const nextYearQ1Period = getQuarterPeriodByYearAndQuarterNumber(
    subYears(currentYearStartDate, -1),
    1
  )!

  const last12MonthsStart = formatDate2(getDateMinusMonth(todayDate, 12))
  const before12MonthsStart = formatDate2(
    getDateMinusDays(getDateMinusMonth(todayDate, 12), 1)
  )
  const before12MonthsEnd = formatDate2(getDateMinusMonth(todayDate, 24))

  const lastQuarterStart = formatDate2(
    getDateMinusMonth(currentQuarterStartDate, 3)
  )

  const lastQuarterEnd = formatDate2(
    getDateMinusDays(currentQuarterStartDate, 1)
  )

  const { startDate: currentMonthStartDate, endDate: currentMonthEndDate } =
    getMonthStartDateAndEndDate(currentCalendarYear, currentMonth)

  const { startDate: lastMonthStartDate, endDate: lastMonthEndDate } =
    getMonthStartDateAndEndDate(currentCalendarYear, currentMonth - 1)

  const {
    startDate: beforeLastMonthStartDate,
    endDate: beforeLastMonthEndDate
  } = getMonthStartDateAndEndDate(currentCalendarYear, currentMonth - 2)

  return {
    [PERIOD_ID.LAST_YEAR]: {
      periodId: PERIOD_ID.LAST_YEAR,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.YEAR,
      dateRange: formatDateRange2([lastYearStartDate, lastYearEndDate]),
      isFuture: false,
      compareDateRange: formatDateRange2([
        secondLastYearStartDate,
        secondLastYearEndDate
      ]),
      trendDateRange: formatDateRange2([
        secondLastYearStartDate,
        lastYearEndDate
      ])
    },
    [PERIOD_ID.CURRENT_YEAR]: {
      periodId: PERIOD_ID.CURRENT_YEAR,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.YEAR,
      dateRange: formatDateRange2([currentYearStartDate, currentYearEndDate]),
      isFuture: true,
      compareDateRange: formatDateRange2([lastYearStartDate, lastYearEndDate]),
      trendDateRange: formatDateRange2([lastYearStartDate, currentYearEndDate])
    },
    [PERIOD_ID.NEXT_YEAR]: {
      periodId: PERIOD_ID.NEXT_YEAR,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.YEAR,
      dateRange: formatDateRange2([nextYearStartDate, nextYearEndDate]),
      isFuture: true,
      compareDateRange: formatDateRange2([
        currentYearStartDate,
        currentYearEndDate
      ]),
      trendDateRange: formatDateRange2([currentYearStartDate, nextYearEndDate])
    },
    [PERIOD_ID.LAST_YEAR_Q1]: {
      periodId: PERIOD_ID.LAST_YEAR_Q1,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(lastYearQ1Period),
      isFuture: false,
      compareDateRange: formatDateRange2(secondLastYearQ4Period),
      trendDateRange: formatDateRange2([
        secondLastYearQ4Period[0],
        lastYearQ1Period[1]
      ])
    },
    [PERIOD_ID.LAST_YEAR_Q2]: {
      periodId: PERIOD_ID.LAST_YEAR_Q2,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(lastYearQ2Period),
      isFuture: false,
      compareDateRange: formatDateRange2(lastYearQ1Period),
      trendDateRange: formatDateRange2([
        lastYearQ1Period[0],
        lastYearQ2Period[1]
      ])
    },
    [PERIOD_ID.LAST_YEAR_Q3]: {
      periodId: PERIOD_ID.LAST_YEAR_Q3,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(lastYearQ3Period),
      isFuture: false,
      compareDateRange: formatDateRange2(lastYearQ2Period),
      trendDateRange: formatDateRange2([
        lastYearQ2Period[0],
        lastYearQ3Period[1]
      ])
    },
    [PERIOD_ID.LAST_YEAR_Q4]: {
      periodId: PERIOD_ID.LAST_YEAR_Q4,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(lastYearQ4Period),
      isFuture: false,
      compareDateRange: formatDateRange2(lastYearQ3Period),
      trendDateRange: formatDateRange2([
        lastYearQ3Period[0],
        lastYearQ4Period[1]
      ])
    },
    [PERIOD_ID.CURRENT_YEAR_Q1]: {
      periodId: PERIOD_ID.CURRENT_YEAR_Q1,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(currentYearQ1Period),
      isFuture: currentQuarterNumber === 1,
      compareDateRange: formatDateRange2(lastYearQ4Period),
      trendDateRange: formatDateRange2([
        lastYearQ4Period[0],
        currentYearQ1Period[1]
      ])
    },
    [PERIOD_ID.CURRENT_YEAR_Q2]: {
      periodId: PERIOD_ID.CURRENT_YEAR_Q2,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(currentYearQ2Period),
      isFuture: currentQuarterNumber <= 2,
      compareDateRange: formatDateRange2(currentYearQ1Period),
      trendDateRange: formatDateRange2([
        currentYearQ1Period[0],
        currentYearQ2Period[1]
      ])
    },
    [PERIOD_ID.CURRENT_YEAR_Q3]: {
      periodId: PERIOD_ID.CURRENT_YEAR_Q3,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(currentYearQ3Period),
      isFuture: currentQuarterNumber <= 3,
      compareDateRange: formatDateRange2(currentYearQ2Period),
      trendDateRange: formatDateRange2([
        currentYearQ2Period[0],
        currentYearQ3Period[1]
      ])
    },
    [PERIOD_ID.CURRENT_YEAR_Q4]: {
      periodId: PERIOD_ID.CURRENT_YEAR_Q4,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(currentYearQ4Period),
      isFuture: currentQuarterNumber <= 4,
      compareDateRange: formatDateRange2(currentYearQ3Period),
      trendDateRange: formatDateRange2([
        currentYearQ3Period[0],
        currentYearQ4Period[1]
      ])
    },
    [PERIOD_ID.CURRENT_MONTH]: {
      periodId: PERIOD_ID.CURRENT_MONTH,
      periodType: PeriodType.MONTH,
      dateRange: formatDateRange2([currentMonthStartDate, currentMonthEndDate]),
      isFuture: true,
      compareDateRange: formatDateRange2([
        lastMonthStartDate,
        lastMonthEndDate
      ]),
      trendDateRange: formatDateRange2([
        lastMonthStartDate,
        currentMonthEndDate
      ])
    },
    [PERIOD_ID.NEXT_YEAR_Q1]: {
      periodId: PERIOD_ID.NEXT_YEAR_Q1,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.QUARTER,
      dateRange: formatDateRange2(nextYearQ1Period),
      isFuture: true,
      compareDateRange: formatDateRange2(currentYearQ4Period),
      trendDateRange: formatDateRange2([
        currentYearQ4Period[0],
        nextYearQ1Period[1]
      ])
    },
    [PERIOD_ID.MTD]: {
      periodId: PERIOD_ID.MTD,
      periodType: PeriodType.CUSTOM,
      dateRange: formatDateRange2([currentMonthStartDate, todayDate]),
      isFuture: false,
      compareDateRange: formatDateRange2([
        lastMonthStartDate,
        lastMonthEndDate
      ]),
      trendDateRange: formatDateRange2([lastMonthStartDate, todayDate])
    },
    [PERIOD_ID.QTD]: {
      periodId: PERIOD_ID.QTD,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.CUSTOM,
      dateRange: formatDateRange2([currentQuarterStartDate, todayDate]),
      isFuture: false,
      compareDateRange: [lastQuarterStart, lastQuarterEnd],
      trendDateRange: [lastQuarterStart, formatDate2(todayDate)]
    },
    [PERIOD_ID.YTD]: {
      periodId: PERIOD_ID.YTD,
      isFiscal: isInFiscalMode,
      periodType: PeriodType.CUSTOM,
      dateRange: formatDateRange2([currentYearStartDate, todayDate]),
      isFuture: false,
      compareDateRange: formatDateRange2([lastYearStartDate, lastYearEndDate]),
      trendDateRange: formatDateRange2([lastYearStartDate, todayDate])
    },
    [PERIOD_ID.LAST_12_MONTHS_TO_DATE]: {
      periodId: PERIOD_ID.LAST_12_MONTHS_TO_DATE,
      periodType: PeriodType.YEAR,
      dateRange: [last12MonthsStart, formatDate2(todayDate)],
      isFuture: false,
      compareDateRange: [before12MonthsStart, before12MonthsEnd],
      trendDateRange: [before12MonthsStart, formatDate2(todayDate)]
    },
    [PERIOD_ID.LAST_MONTH]: {
      periodId: PERIOD_ID.LAST_MONTH,
      periodType: PeriodType.MONTH,
      dateRange: formatDateRange2([lastMonthStartDate, lastMonthEndDate]),
      isFuture: false,
      compareDateRange: formatDateRange2([
        beforeLastMonthStartDate,
        beforeLastMonthEndDate
      ]),
      trendDateRange: formatDateRange2([
        beforeLastMonthStartDate,
        lastMonthEndDate
      ])
    }
  }
}

export const isPeriodInvalid = (startDate: string, endDate: string) => {
  return (
    !isDateValid(startDate) ||
    !isDateValid(endDate) ||
    new Date(startDate) > new Date(endDate)
  )
}

export const getRangeLength = (value: string[]) => {
  const date1 = new Date(value[0]!)
  const date2 = new Date(value[1]!)
  return differenceInDays(date2, date1)
}

const getTrendOrChartSubPeriodFromDiffDays = (diffDays: number) => {
  if (diffDays < 10) {
    return TimeGrain.DAY
  } else if (diffDays < 60) {
    return TimeGrain.WEEK
  } else if (diffDays < 180) {
    return TimeGrain.MONTH
  } else if (diffDays < 730) {
    return TimeGrain.QUARTER
  } else {
    return TimeGrain.YEAR
  }
}

export const buildCompletePeriodFromPeriodOption = (periodOption: Period) => {
  if (periodOption.periodType === PeriodType.CUSTOM) {
    return buildCompleteCustomPeriodFromPeriod(periodOption)
  }

  return {
    ...periodOption,
    trendSubPeriod: PERIOD_OPTIONS[periodOption.periodType].trendSubPeriod,
    chartSubPeriod: PERIOD_OPTIONS[periodOption.periodType].chartSubPeriod
  }
}

export const buildCompleteCustomPeriodFromPeriod = (periodOption: Period) => ({
  ...periodOption,
  trendSubPeriod: getTrendOrChartSubPeriodFromDiffDays(
    getRangeLength(periodOption.trendDateRange)
  ),
  chartSubPeriod: getTrendOrChartSubPeriodFromDiffDays(
    getRangeLength(periodOption.dateRange)
  )
})

export const buildCustomPeriodFromDateRange = (dateRange: string[]) => {
  const startDate = new Date(dateRange[0]!)
  const endDate = new Date(dateRange[1]!)
  const todayDate = new Date()
  const isFuture = startDate > todayDate || endDate > todayDate
  const diffDays = getRangeLength(dateRange)
  const compareDateRange = [
    formatDate2(getDateMinusDays(startDate, diffDays + 1)),
    formatDate2(getDateMinusDays(startDate, 1))
  ]
  const trendDateRange = [compareDateRange[0]!, dateRange[1]!]

  return buildCompleteCustomPeriodFromPeriod({
    periodId: PERIOD_ID.CUSTOM,
    periodType: PeriodType.CUSTOM,
    dateRange: [dateRange[0]!, dateRange[1]!],
    isFuture,
    compareDateRange,
    trendDateRange
  })
}

export const formatSelectedPeriod = (
  isCustomPeriodSelected: boolean,
  selectedPeriod: BasicPeriod
) => {
  return {
    label: isCustomPeriodSelected
      ? `${selectedPeriod.dateRange[0]} - ${selectedPeriod.dateRange[1]}`
      : formatPeriodOption(selectedPeriod),
    isFuture: selectedPeriod.isFuture,
    id: selectedPeriod.periodId,
    isCustomChoice: isCustomPeriodSelected
  }
}

export const formatPeriodOptions = (periodOptions: Period[]) => {
  return periodOptions.map(option => ({
    label: formatPeriodOption(option),
    isFuture: option.isFuture,
    id: option.periodId
  }))
}

export const getPeriodFromDateRange = (
  dateRange: string[],
  periodOptions: Period[]
): FullPeriod => {
  const periodOption = periodOptions.find(
    periodOption =>
      periodOption.dateRange[0] === dateRange[0] &&
      periodOption.dateRange[1] === dateRange[1]
  )

  if (periodOption) {
    return buildCompletePeriodFromPeriodOption(periodOption)
  } else {
    return buildCustomPeriodFromDateRange(dateRange)
  }
}

export const areSamePeriods = (
  period1: [string, string],
  period2: [string, string]
) => period1[0] === period2[0] && period1[1] === period2[1]

export const getMultiYearComparePeriods = (
  startDate: Date,
  endDate: Date,
  isFiscal: boolean
): [string, string][] => {
  const numberOfYears = isFiscal
    ? getFiscalYear(endDate) - getFiscalYear(startDate) + 1
    : endDate.getFullYear() - startDate.getFullYear() + 1

  return Array.from({ length: numberOfYears }, (_, index) => [
    formatDate2(addYears(startDate, index)),
    index + 1 === numberOfYears
      ? formatDate2(endDate)
      : formatDate2(addDays(addYears(startDate, index + 1), -1))
  ])
}

export const getYearsFromDateRange = (
  startDate: Date,
  endDate: Date
): string[] => {
  const { isInFiscalMode } = useCustomizationStore()

  const startYear = isInFiscalMode
    ? getFiscalYear(startDate)
    : startDate.getFullYear()

  const numberOfYears = isInFiscalMode
    ? getFiscalYear(endDate) - getFiscalYear(startDate) + 1
    : endDate.getFullYear() - startDate.getFullYear() + 1

  return Array.from({ length: numberOfYears }, (_, index) =>
    (startYear + index).toString()
  )
}

export const getFiscalYearDateRange = (year: number): [Date, Date] => {
  const { fiscalYearStartMonth } = useCustomizationStore()
  const endDate = new Date(year, fiscalYearStartMonth - 1, 0)
  const startDate = new Date(year - 1, fiscalYearStartMonth - 1, 1)

  return [startDate, endDate]
}

export const getYearDateRange = (
  year: number,
  isFiscal: boolean
): [Date, Date] => {
  if (isFiscal) {
    return getFiscalYearDateRange(year)
  } else {
    return [new Date(year, 0, 1), new Date(year, 11, 31)]
  }
}

export const isDateRangeMatchingPastFiscalYearOrYearToDate = (
  dateRange: [string, string]
) => {
  const { isInFiscalMode } = useCustomizationStore()

  const [startDate, endDate] = [new Date(dateRange[0]), new Date(dateRange[1])]
  const todayDate = new Date()

  const year = isInFiscalMode
    ? getFiscalYear(startDate)
    : startDate.getFullYear()

  const currentYear = isInFiscalMode
    ? getFiscalYear(todayDate)
    : todayDate.getFullYear()

  if (year === currentYear) {
    const [currentYearStartDate] = getYearDateRange(currentYear, isInFiscalMode)
    return (
      isSameDay(startDate, currentYearStartDate) &&
      isSameDay(endDate, todayDate)
    )
  } else if (year < currentYear) {
    const [yearStartDate, yearEndDate] = getYearDateRange(year, isInFiscalMode)
    return (
      isSameDay(startDate, yearStartDate) && isSameDay(endDate, yearEndDate)
    )
  } else {
    return false
  }
}

export const getFiscalQuarterDateRange = (
  periodValue: string
): [Date, Date] => {
  const [year, quarter] = periodValue
    .split('.')
    .map(value => parseInt(value)) as [number, number]

  return getQuarterPeriodByYearAndQuarterNumber(
    getFiscalYearDateRange(year)[0],
    quarter
  )
}

export const getFiscalYear = (date: Date): number => {
  const { fiscalYearStartMonth } = useCustomizationStore()

  return date.getMonth() < fiscalYearStartMonth - 1
    ? date.getFullYear()
    : date.getFullYear() + 1
}

export const getYearStartDate = (date: Date, isFiscal: boolean): Date => {
  if (isFiscal) {
    const dateYear = date.getFullYear()

    const fiscalYearStartMonth = useCustomizationStore().fiscalYearStartMonth

    const startYear =
      date.getMonth() < fiscalYearStartMonth - 1 ? dateYear - 1 : dateYear
    const month = fiscalYearStartMonth - 1

    return new Date(startYear, month, 1)
  } else {
    return startOfYear(date)
  }
}

export const formatPeriodOption = (period: BasicPeriod): string => {
  const { $t } = useNuxtApp()

  const periodEndDate = new Date(period.dateRange[1]!)
  const year = period.isFiscal
    ? getFiscalYear(periodEndDate)
    : periodEndDate.getFullYear()

  return $t(
    `dashboard.filters.time.choices.${period.isFiscal ? 'fiscal' : 'default'}.${
      period.periodId
    }`,
    {
      year
    }
  )
}

export const getFiscalFormat = (value: string, isFiscal: boolean): string => {
  return `${isFiscal ? 'FY ' : ''}${value}`
}
