import type { PERIOD_ID } from '~/constants/period'

export enum PeriodType {
  YEAR = 'year',
  QUARTER = 'quarter',
  MONTH = 'month',
  CUSTOM = 'custom'
}

export enum TimeGrain {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

export enum CustomTimeGrain {
  FISCAL_QUARTER_1 = 'fiscalQuarter1',
  FISCAL_QUARTER_2 = 'fiscalQuarter2',
  FISCAL_YEAR_1 = 'fiscalYear1',
  FISCAL_YEAR_2 = 'fiscalYear2',
  FISCAL_YEAR_3 = 'fiscalYear3',
  FISCAL_YEAR_4 = 'fiscalYear4',
  FISCAL_YEAR_5 = 'fiscalYear5',
  FISCAL_YEAR_6 = 'fiscalYear6',
  FISCAL_YEAR_7 = 'fiscalYear7',
  FISCAL_YEAR_8 = 'fiscalYear8',
  FISCAL_YEAR_9 = 'fiscalYear9',
  FISCAL_YEAR_10 = 'fiscalYear10',
  FISCAL_YEAR_11 = 'fiscalYear11'
}

export interface BasicPeriod {
  periodId: PERIOD_ID
  periodType: PeriodType
  dateRange: string[]
  isFuture: boolean
  isFiscal?: boolean
}

export interface Period extends BasicPeriod {
  compareDateRange: string[]
  trendDateRange: string[]
}

export interface FullPeriod extends Period {
  trendSubPeriod: TimeGrain
  chartSubPeriod: TimeGrain
}

export enum TimeDimensionStoreContext {
  DASHBOARD = 'DASHBOARD',
  SETTINGS = 'SETTINGS',
  LEGAL_REPORT = 'LEGAL_REPORT'
}
