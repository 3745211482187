import { gql } from 'graphql-tag'
import { LEGAL_REPORT_FRAGMENT } from './legalReportFragment'

export const REMOVE_LEGAL_REPORT_SECTION_MUTATION = gql`
  ${LEGAL_REPORT_FRAGMENT}
  mutation REMOVE_LEGAL_REPORT_SECTION_MUTATION(
    $id: String!
    $sectionId: String!
  ) {
    removeLegalReportSection(id: $id, sectionId: $sectionId) {
      ...LegalReportFields
    }
  }
`
