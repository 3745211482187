import type { NormalizedFieldEntry } from '../sourceValue'
import type { Filter } from '~/types/analytics'

export type LegalReportQuery = {
  measure: string
  rowDimensions: string[]
  columnDimension?: string
  filters?: Filter[]
  dateRange?: [string, string]
}

export enum ExplorerChartContentType {
  BAR_CHART = 'BAR_CHART',
  DOUGHNUT_CHART = 'DOUGHNUT_CHART'
}

export enum ExplorerTableContentType {
  YEAR_TABLE = 'YEAR_TABLE',
  STANDARD_TABLE = 'STANDARD_TABLE'
}

export type ExplorerContentType =
  | ExplorerChartContentType
  | ExplorerTableContentType

export type ExplorerNewContentContext = {
  isNewContent: true
  type: ExplorerContentType
  query?: undefined
}

export type ExplorerExistingContentContext = {
  isNewContent?: false
  query: LegalReportQuery
  associatedNormalizedFields: NormalizedFieldEntry[]
  type: ExplorerContentType
  withTotal?: boolean
  first10Only?: boolean
}

export enum DynamicContentType {
  EXPLORER = 'EXPLORER',
  ATTACHMENT_ZONE = 'ATTACHMENT_ZONE'
}

type BaseDynamicContentMeta = {
  yPosition: number
  height: number
  nodePos: number
  type: DynamicContentType
}

export type ExplorerContentMeta = BaseDynamicContentMeta & {
  explorerContext: ExplorerExistingContentContext
  importedAt: string
  type: DynamicContentType.EXPLORER
}

export type AttachmentZoneContentMeta = BaseDynamicContentMeta & {
  attachmentIds: string[]
  updatedAt: string | null
  type: DynamicContentType.ATTACHMENT_ZONE
}

export type DynamicContentMeta = ExplorerContentMeta | AttachmentZoneContentMeta

export type ExplorerContext =
  | ExplorerNewContentContext
  | ExplorerExistingContentContext
