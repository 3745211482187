import { gql } from 'graphql-tag'
import { LEGAL_REPORT_SECTION_FRAGMENT } from './legalReportFragment'

export const UPDATE_LEGAL_REPORT_SECTION_MUTATION = gql`
  ${LEGAL_REPORT_SECTION_FRAGMENT}
  mutation UPDATE_LEGAL_REPORT_SECTION_MUTATION(
    $id: String!
    $sectionId: String!
    $newContent: String!
    $newTitle: String!
  ) {
    updateLegalReportSection(
      id: $id
      sectionId: $sectionId
      newContent: $newContent
      newTitle: $newTitle
    ) {
      ...LegalReportSectionFields
    }
  }
`
