import { gql } from 'graphql-tag'
import { LEGAL_REPORT_WITH_ACCESS_TYPE_FRAGMENT } from './legalReportFragment'

export const GET_LEGAL_REPORT_QUERY = gql`
  ${LEGAL_REPORT_WITH_ACCESS_TYPE_FRAGMENT}
  query GET_LEGAL_REPORT_QUERY($id: String!) {
    getLegalReport(id: $id) {
      ...LegalReportWithAccessTypeFields
    }
  }
`
