import { gql } from 'graphql-tag'
import { LEGAL_REPORT_FRAGMENT } from './legalReportFragment'

export const PUBLISH_LEGAL_REPORT_MUTATION = gql`
  ${LEGAL_REPORT_FRAGMENT}
  mutation PUBLISH_LEGAL_REPORT_MUTATION(
    $id: String!
    $publicationNote: String
    $withEmailNotification: Boolean!
  ) {
    publishLegalReport(
      publicationNote: $publicationNote
      id: $id
      withEmailNotification: $withEmailNotification
    ) {
      ...LegalReportFields
    }
  }
`
