import { gql } from 'graphql-tag'
import { LEGAL_REPORT_FRAGMENT } from './legalReportFragment'

export const SORT_LEGAL_REPORT_SECTIONS_MUTATION = gql`
  ${LEGAL_REPORT_FRAGMENT}
  mutation SORT_LEGAL_REPORT_SECTIONS_MUTATION(
    $id: String!
    $sortedSectionIds: [String!]!
  ) {
    sortLegalReportSections(id: $id, sortedSectionIds: $sortedSectionIds) {
      ...LegalReportFields
    }
  }
`
