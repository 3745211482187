import {
  LegalReportType,
  type LegalReport,
  type PartialLegalReport,
  type LegalReportWithAccessType,
  LegalReportVersion,
  type LegalReportSection,
  type LegalReportAttachment,
  type LegalReportBaseSection
} from '~/types/legalReports/main'
import { GET_COMPANY_LEGAL_REPORTS_QUERY } from '~/gql/legalReports/getCompanyLegalReportsQuery'
import { hasSameFilterValues } from '~/services/filters'
import type { Filter } from '~/types/analytics'
import { GET_LEGAL_REPORT_QUERY } from '~/gql/legalReports/getLegalReportQuery'
import { ROUTE } from '~/constants/routes'
import { REMOVE_LEGAL_REPORT_SECTION_MUTATION } from '~/gql/legalReports/removeLegalReportSectionMutation'
import { SORT_LEGAL_REPORT_SECTIONS_MUTATION } from '~/gql/legalReports/sortLegalReportSectionsMutation'
import { DELETE_LEGAL_REPORT_ATTACHMENT_MUTATION } from '~/gql/legalReports/attachments/deleteLegalReportAttachmentMutation'
import { getMainLegalReportFilter } from '~/services/legalReports/main'
import { CREATE_LEGAL_REPORT_SECTION_MUTATION } from '~/gql/legalReports/createLegalReportSectionMutation'
import { PUBLISH_LEGAL_REPORT_MUTATION } from '~/gql/legalReports/publishLegalReportMutation'
import { UPDATE_LEGAL_REPORT_SECTION_MUTATION } from '~/gql/legalReports/updateLegalReportSectionMutation'
import { DELETE_LEGAL_REPORT_MUTATION } from '~/gql/legalReports/deleteLegalReportMutation'

interface State {
  availableLegalReports: undefined | PartialLegalReport[]
  fullLegalReport: null | LegalReport
  legalReportVersion: null | LegalReportVersion
  hasEditorAccess: boolean
}
export const useLegalReportsStore = defineStore('legalReports', {
  state: (): State => ({
    availableLegalReports: undefined,
    fullLegalReport: null,
    legalReportVersion: null,
    hasEditorAccess: false
  }),
  getters: {
    hasPublishedVersion: (state: State) => {
      if (!state.fullLegalReport) {
        return false
      }
      return state.fullLegalReport.legalReportSections.some(
        section => section.isPublished
      )
    },
    legalReport: (state: State) => {
      if (!state.fullLegalReport) {
        return null
      }

      if (state.legalReportVersion === null) {
        return state.fullLegalReport
      }

      return {
        ...state.fullLegalReport,
        legalReportSections: state.fullLegalReport.legalReportSections.filter(
          section =>
            (state.legalReportVersion === LegalReportVersion.PUBLISHED &&
              section.isPublished) ||
            (state.legalReportVersion === LegalReportVersion.DRAFT &&
              !section.isPublished)
        ),
        legalReportAttachments:
          state.fullLegalReport.legalReportAttachments.filter(
            attachment =>
              (state.legalReportVersion === LegalReportVersion.PUBLISHED &&
                attachment.isPublished) ||
              (state.legalReportVersion === LegalReportVersion.DRAFT &&
                !attachment.isPublished)
          )
      }
    }
  },
  actions: {
    setSelectedLegalReport(legalReport: LegalReport | null) {
      this.fullLegalReport = legalReport
      if (legalReport) {
        // In legal report creation case
        this.hasEditorAccess = true
      }
    },
    setVersion(legalReportVersion: LegalReportVersion) {
      this.legalReportVersion = legalReportVersion
    },
    async getAvailableLegalReports() {
      const { $apiGqlClient } = useNuxtApp()

      const {
        data: { getCompanyLegalReports }
      }: { data: { getCompanyLegalReports: PartialLegalReport[] } } =
        await $apiGqlClient.query({
          query: GET_COMPANY_LEGAL_REPORTS_QUERY
        })

      this.availableLegalReports = getCompanyLegalReports
    },
    async fetchLegalReportFromRouteParam() {
      const route = useRoute()
      const legalReportId = route.params.legalReport?.toString()

      if (!legalReportId) {
        return await navigateTo(ROUTE.LEGAL_REPORTS)
      }

      if (this.fullLegalReport?.id === legalReportId) {
        return
      }

      this.fullLegalReport = null

      const { $apiGqlClient } = useNuxtApp()

      const legalReport = (
        await $apiGqlClient.query({
          query: GET_LEGAL_REPORT_QUERY,
          variables: { id: legalReportId }
        })
      )?.data?.getLegalReport as LegalReportWithAccessType | undefined

      if (!legalReport) {
        return await navigateTo(ROUTE.LEGAL_REPORTS)
      }

      this.fullLegalReport = legalReport
      this.hasEditorAccess = legalReport.hasEditorAccess
    },
    updateAvailableLegalReports(legalReport: PartialLegalReport) {
      const legalReportIndex = this.availableLegalReports!.findIndex(
        report => report.id === legalReport.id
      )

      if (legalReportIndex! < 0) {
        this.availableLegalReports = [
          ...this.availableLegalReports!,
          legalReport
        ]
      } else {
        this.availableLegalReports = this.availableLegalReports!.toSpliced(
          legalReportIndex,
          1,
          legalReport
        )
      }
    },
    getLegalReportByUniquenessConstraint(
      year: number,
      type: LegalReportType,
      mainFilter: Filter | undefined
    ) {
      return this.availableLegalReports!.find(
        legalReport =>
          legalReport.year === year &&
          legalReport.type === type &&
          hasSameFilterValues(
            getMainLegalReportFilter(legalReport.legalReportFilters)?.values,
            mainFilter?.values
          )
      )
    },
    async publishNewVersion(
      publicationNote: string | undefined,
      withEmailNotification: boolean
    ) {
      const { $apiGqlClient } = useNuxtApp()
      const updatedLegalReport = (
        await $apiGqlClient.mutate({
          mutation: PUBLISH_LEGAL_REPORT_MUTATION,
          variables: {
            id: this.fullLegalReport!.id,
            publicationNote,
            withEmailNotification
          }
        })
      ).data.publishLegalReport as LegalReport

      this.updateAvailableLegalReports({
        id: updatedLegalReport.id,
        year: updatedLegalReport.year,
        type: updatedLegalReport.type,
        realizedYearsNumber: updatedLegalReport.realizedYearsNumber,
        trendYearsNumber: updatedLegalReport.trendYearsNumber,
        createdAt: updatedLegalReport.createdAt,
        publishedAt: updatedLegalReport.publishedAt,
        legalReportFilters: updatedLegalReport.legalReportFilters,
        initialTemplate: updatedLegalReport.initialTemplate
      })

      this.fullLegalReport = updatedLegalReport
    },
    // Sections edition
    async updateSection(
      sectionId: string,
      newContent: string,
      newTitle: string
    ) {
      const { $apiGqlClient } = useNuxtApp()

      const updatedSection = (
        await $apiGqlClient.mutate({
          mutation: UPDATE_LEGAL_REPORT_SECTION_MUTATION,
          variables: {
            id: this.fullLegalReport!.id,
            sectionId,
            newContent,
            newTitle
          }
        })
      ).data.updateLegalReportSection as LegalReportSection

      const sectionIndex = this.fullLegalReport!.legalReportSections.findIndex(
        section => section.id === sectionId
      )!

      this.fullLegalReport!.legalReportSections[sectionIndex] = updatedSection
    },
    async sortSections(sortedLegalReportSections: LegalReportSection[]) {
      const { $apiGqlClient } = useNuxtApp()
      const updatedLegalReport = (
        await $apiGqlClient.mutate({
          mutation: SORT_LEGAL_REPORT_SECTIONS_MUTATION,
          variables: {
            id: this.fullLegalReport!.id,
            sortedSectionIds: sortedLegalReportSections.map(
              section => section.id
            )
          }
        })
      ).data.sortLegalReportSections as LegalReport

      this.fullLegalReport = updatedLegalReport
    },
    async addSection(section: LegalReportBaseSection) {
      const { $apiGqlClient } = useNuxtApp()

      const updatedLegalReport = (
        await $apiGqlClient.mutate({
          mutation: CREATE_LEGAL_REPORT_SECTION_MUTATION,
          variables: {
            id: this.fullLegalReport!.id,
            section
          }
        })
      ).data.createLegalReportSection as LegalReport

      this.fullLegalReport = updatedLegalReport
    },
    async removeSection(sectionToRemoveId: string) {
      const { $apiGqlClient } = useNuxtApp()
      const updatedLegalReport = (
        await $apiGqlClient.mutate({
          mutation: REMOVE_LEGAL_REPORT_SECTION_MUTATION,
          variables: {
            id: this.fullLegalReport!.id,
            sectionId: sectionToRemoveId
          }
        })
      ).data.removeLegalReportSection as LegalReport

      this.fullLegalReport = updatedLegalReport
    },
    // Attachments
    addAttachments(attachments: LegalReportAttachment[]) {
      this.fullLegalReport!.legalReportAttachments = [
        ...this.fullLegalReport!.legalReportAttachments,
        ...attachments
      ]
    },
    async deleteAttachment(id: string) {
      const { $apiGqlClient } = useNuxtApp()
      await $apiGqlClient.mutate({
        mutation: DELETE_LEGAL_REPORT_ATTACHMENT_MUTATION,
        variables: {
          id
        }
      })

      const index = this.fullLegalReport!.legalReportAttachments.findIndex(
        attachment => attachment.id === id
      )!

      this.fullLegalReport!.legalReportAttachments =
        this.fullLegalReport!.legalReportAttachments.toSpliced(index, 1)
    },
    replaceAttachment(updatedAttachment: LegalReportAttachment) {
      const index = this.fullLegalReport!.legalReportAttachments.findIndex(
        attachment => attachment.id === updatedAttachment.id
      )!

      this.fullLegalReport!.legalReportAttachments =
        this.fullLegalReport!.legalReportAttachments.toSpliced(
          index,
          1,
          updatedAttachment
        )
    },
    async deleteLegalReport() {
      const { $apiGqlClient } = useNuxtApp()
      await $apiGqlClient.mutate({
        mutation: DELETE_LEGAL_REPORT_MUTATION,
        variables: {
          id: this.fullLegalReport!.id
        }
      })

      this.availableLegalReports = this.availableLegalReports!.filter(
        report => report.id !== this.fullLegalReport!.id
      )
    }
  }
})
